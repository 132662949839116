import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';

import { AuthenticationService } from '@authentication/authentication.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'logout',
  standalone: true,
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, OnDestroy {
  timeout: number = 0;
  private storageService: StorageService;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService
  ) {
    this.storageService = new StorageService();
  }

  ngOnInit(): void {
    this.logout();
  }

  async logout() {
    try {
      await this.authenticationService.logout();

      this.handleLogout();
    } catch (error) {
      this.handleLogout();
    }
  }

  private handleLogout() {
    this.storageService.clear();
    this.cookieService.deleteAll();

    this.timeout = window.setTimeout(() => {
      this.router.navigate(['/prijava']);
    }, 2500);
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.timeout);
  }
}
