<footer class="navbar">
  <div class="container-fluid">
    <span class="rights">
      © 2024
      <a href="https://swing-consulting.hr/" target="_blank">
        SWING Consulting d.o.o.
      </a>
    </span>
    <span class="logo">
      <img src="assets/img/logo/logo-32.png"
        srcset="assets/img/logo/logo-32.png 32w,
          assets/img/logo/logo-48.png 48w,
          assets/img/logo/logo-64.png 64w"
        sizes="(max-width: 1024px) 32px, (max-width: 1440px) 48px, 64px"
        alt="Swing">
    </span>
    <span class="info">
      <ul class="navbar-nav justify-content-end">
        <li class="nav-item">
          <a class="nav-link px-3 text-muted">{{ 'labels.about' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-3 text-muted"
            href="https://support.swing-consulting.hr/portal/hr/home"
            target="_blank"
          >
            {{ 'labels.support' | translate }}
          </a>
        </li>
      </ul>
    </span>
  </div>
</footer>
