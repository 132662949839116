import { Component } from '@angular/core';
import { FooterComponent } from './components/footer/footer.component';
import { ProfileModalComponent } from '../profile-modal/profile-modal.component';
import { RouterOutlet } from '@angular/router';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './components/header/header.component';

@Component({
    selector: 'navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: true,
    imports: [HeaderComponent, BreadcrumbComponent, RouterOutlet, ProfileModalComponent, FooterComponent]
})
export class NavigationComponent {}
