<p-sidebar
  [(visible)]="sidebar"
  position="right"
  styleClass="right-sidebar"
  transitionOptions="0.5s"
  (onHide)="hide()"
>
  <ng-template pTemplate="header">
    <div class="actions" [ngClass]="{ 'single-btn': !administration }">
      <p-button
        *ngIf="administration"
        label="Administracija"
        styleClass="action-btn"
        (onClick)="action('administracija')"
      >
      </p-button>
      <p-button
        label="Odjava"
        styleClass="action-btn"
        (onClick)="action('odjava')"
      >
      </p-button>
    </div>
    <span class="mb-24">{{ tenant.tenantData.name }}</span>
    <span class="mb-8">{{ user.basicInfo.email }}</span>
    <span class="name mt-16">{{ name | async }}</span>
    <img class="avatar rounded-circle" [src]="image | async" alt="avatar" />
  </ng-template>
  <ng-template pTemplate="content">
    <div class="links">
      <a class="mt-3" routerLink="/" (click)="hide()">Početna</a>
      <a class="mt-3" routerLink="/evidencije" (click)="hide()">Evidencije</a>
      <a class="mt-3" routerLink="/postavke" (click)="hide()">Postavke</a>
    </div>
  </ng-template>
</p-sidebar>
