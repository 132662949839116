<div class="container">
  <div class="row">
    <div class="col align-self-center">
      <div class="wrapper">
        <div class="form-logo">
          <img src="/assets/img/logo/logo-name-125.png"
            srcset="/assets/img/logo/logo-name-125.png 125w,
              assets/img/logo/logo-name-100.png 100w,
              assets/img/logo/logo-name-75.png 75w,
              assets/img/logo/logo-name-50.png 50w"
            sizes="(max-width: 430px) 50px, (max-width: 530px) 75px,
              (max-width: 768px) 100px, 125px"
            alt="Swing">
        </div>
        <div class="form-header">
          <h5>Potvrda registracije</h5>
          <p>Poslana vam je obavijest na e-mail koji ste unijeli,
            a koja sadržava link za potvrdu registracije.</p>
          <p>Molimo vas provjerite svoj inbox i
            slijedite upute iz e-maila.</p>
          <p class="warning">PRISTUP APLIKACIJI MOGUĆ JE NAKON POTVRDE REGISTRACIJE!</p>
        </div>
        <p-button label="Ponovno pošalji" styleClass="p-button-lg"
          (onClick)="sendToVerify()"
        >
        </p-button>
      </div>
    </div>
  </div>
</div>
