import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { UserProfileService } from 'src/app/shared/services/user-profile.service';

export const verificationGuard: CanActivateFn = (route, state) => {
  const userProfileService = inject(UserProfileService);
  const router = inject(Router);

  if (userProfileService.getVerification()) {
    return true;
  }
  return router.parseUrl('/potvrda');
};
