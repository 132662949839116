import { Component, EventEmitter, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { AuthenticationService } from 'src/app/core/modules/authentication/authentication.service';
import { CookieConsent as CookieConsentInterface } from '../../interfaces/cookie-consent';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';

class CookieConsent implements CookieConsentInterface {
  constructor(
    public required = true,
    public bugReport = false,
    public deviceInfo = false,
    public location = false
  ) {}

  acceptAll() {
    this.bugReport = true;
    this.deviceInfo = true;
    this.location = true;
  }
}

@Component({
  selector: 'cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  providers: [CookieService],
  standalone: true,
  imports: [
    PanelModule,
    ButtonModule,
    DialogModule,
    InputSwitchModule,
    FormsModule,
  ],
})
export class CookieConsentComponent {
  @Output() onAcceptCookieConsent = new EventEmitter<boolean>();
  cookieConsent = new CookieConsent();
  sessionId = '';
  visible = false;

  constructor(
    private cookieService: CookieService,
    private authenticationService: AuthenticationService
  ) {}

  acceptCookies(type: string) {
    if (type === 'all') {
      this.cookieConsent.acceptAll();
    }
    this.consent();
  }

  async consent() {
    this.cookieService.set('consent', JSON.stringify(this.cookieConsent), {
      expires: 7,
    });
    await this.authenticationService.consent(this.cookieConsent);

    this.onAcceptCookieConsent.emit(true);
    this.visible = false;
  }
}
