<p-sidebar [(visible)]="sidebarVisible" position="right">
  <div class="tenant-form-sidebar">
    <div>
      <h3>Nova evidencija</h3>
      <form #tenantForm="ngForm">
        <div class="tenant-form">
          <div class="form-group">
            <span class="p-float-label">
              <input
                class="tenant-form-input"
                id="tenantName"
                type="text"
                ngModel
                name="tenantName"
                #tenantName="ngModel"
                required
                minlength="3"
                maxlength="64"
                pInputText
              />
              <label for="tenantName">Naziv evidencije</label>
            </span>
            <span class="validation-error">
              {{ formErrorMessages.tenantName }}
            </span>
          </div>
          <div class="form-group">
            <span class="p-float-label">
              <input
                class="tenant-form-input"
                id="companyName"
                type="text"
                ngModel
                name="companyName"
                #companyName="ngModel"
                required
                minlength="3"
                pInputText
              />
              <label for="companyName">Naziv organizacije</label>
            </span>
            <span class="validation-error">
              {{ formErrorMessages.companyName }}
            </span>
          </div>
          <div class="form-group">
            <span class="p-float-label">
              <input
                class="tenant-form-input"
                id="web"
                type="text"
                ngModel
                name="web"
                #web="ngModel"
                pInputText
              />
              <label for="web">Web adresa</label>
            </span>
          </div>
          <div class="form-group">
            <address-search></address-search>
          </div>
          <div class="form-group">
            <country-select></country-select>
            <span class="validation-error">
              {{ formErrorMessages.country }}
            </span>
          </div>
          <div
            class="form-group"
            [ngClass]="{
              none:
                countrySelect !== undefined &&
                countrySelect.selectedCountry !== 'Croatia'
            }"
          >
            <span class="p-float-label">
              <input
                class="tenant-form-input"
                id="oib"
                type="text"
                inputmode="numeric"
                ngModel
                name="oib"
                #oib="ngModel"
                minlength="11"
                maxlength="11"
                pattern="^[0-9]{11}$"
                pInputText
              />
              <label for="oib">Osobni identifikacijski broj (OIB)</label>
            </span>
            <span class="validation-error">
              {{ formErrorMessages.oib }}
            </span>
          </div>
          <div
            class="form-group"
            [ngClass]="{
              none:
                countrySelect !== undefined &&
                countrySelect.selectedCountry !== 'BH' &&
                countrySelect.selectedCountry !== 'Serbia'
            }"
          >
            <span class="p-float-label">
              <input
                class="tenant-form-input"
                id="vat"
                type="text"
                ngModel
                name="vat"
                #vat="ngModel"
                minlength="10"
                maxlength="13"
                pInputText
              />
              <label for="vat">Value added tax (VAT)</label>
            </span>
            <span class="validation-error">
              {{ formErrorMessages.vat }}
            </span>
          </div>
          <div
            class="form-group"
            [ngClass]="{
              none:
                countrySelect !== undefined &&
                countrySelect.selectedCountry !== 'Serbia'
            }"
          >
            <span class="p-float-label">
              <input
                class="tenant-form-input"
                id="jbkjs"
                type="text"
                inputmode="numeric"
                ngModel
                name="jbkjs"
                #jbkjs="ngModel"
                minlength="5"
                maxlength="5"
                pattern="^[0-9]{5}$"
                pInputText
              />
              <label for="jbkjs">
                Jedinstveni broj korisnika javnih sredstava (JBKJS)
              </label>
            </span>
            <span class="validation-error">
              {{ formErrorMessages.jbkjs }}
            </span>
          </div>
        </div>
      </form>
    </div>
    <div class="submit-btn">
      <div class="d-flex justify-content-center">
        <button
          pButton
          label="Spremi evidenciju"
          class="p-button-secondary submit"
          (click)="onCreateTenant()"
        ></button>
      </div>
    </div>
  </div>
</p-sidebar>
