<div class="container">
  <div class="row">
    <div class="col align-self-center">
      <div class="wrapper">
        <form #f="ngForm">
          <div class="form-logo">
            <img
              src="/assets/img/logo/logo-name-125.png"
              srcset="
                /assets/img/logo/logo-name-125.png 125w,
                assets/img/logo/logo-name-100.png  100w,
                assets/img/logo/logo-name-75.png    75w,
                assets/img/logo/logo-name-50.png    50w
              "
              sizes="(max-width: 430px) 50px, (max-width: 530px) 75px,
                (max-width: 768px) 100px, 125px"
              alt="Swing"
            />
          </div>
          <div class="form-header">
            <h5>Promjena lozinke</h5>
            <p>Unesite novu lozinku korisničkog računa</p>
          </div>
          <span class="p-float-label" style="margin-top: 2rem">
            <p-password
              inputId="password"
              [toggleMask]="true"
              [feedback]="false"
              styleClass="password"
              [inputStyleClass]="htmlElementClass()"
              [(ngModel)]="user.password"
              name="password"
              #password="ngModel"
              required
              minlength="8"
            >
            </p-password>
            <label for="password" [ngClass]="{ 'not-submitted': !submitted }"
              >Lozinka</label
            >
          </span>
          <small class="invalid-input" *ngIf="!password.valid && submitted">
            Lozinka mora sadržavati minimalno 8 znakova
          </small>
          <p-button
            label="{{ 'labels.save' | translate }}"
            styleClass="p-button-lg"
            (onClick)="resetPassword()"
          >
          </p-button>
        </form>
      </div>
    </div>
  </div>
</div>
