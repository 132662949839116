import { enableProdMode, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeHrHR from '@angular/common/locales/hr';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { from, Observable } from 'rxjs';

import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler,
} from '@ngx-translate/core';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import { environment } from '@environments/environment';
import { AppRoutingModule } from '@app/app-routing.module';
import { GraphQLModule } from '@app/graphql.module';
import { MissingTranslationHandlerService } from '@services/custom-missing-translation-handler.service';
import { HttpClientService } from '@app/shared/services/http-client.service';
import { AppComponent } from '@app/app.component';

if (environment.production) {
  enableProdMode();
} else {
  loadDevMessages();
  loadErrorMessages();
}

export class createTranslateLoader implements TranslateLoader {
  private httpClientService: HttpClientService;

  constructor() {
    this.httpClientService = new HttpClientService();
  }

  getTranslation(lang: string): Observable<any> {
    return from(this.httpClientService
      .method('GET')
      .baseUrl('')
      .route(`/assets/i18n/${lang}.json`)
      .response<any>());
  }
}

registerLocaleData(localeHrHR, 'hr');

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      GraphQLModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: createTranslateLoader,
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingTranslationHandlerService,
        },
      })
    ),
    { provide: LOCALE_ID, useValue: 'hr' },
    provideHttpClient(),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
