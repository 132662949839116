import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { Password, PasswordModule } from 'primeng/password';

import { CustomValidators } from 'src/app/shared/helpers/custom-validators';
import { AuthUser } from '../../authentication.interface';
import { AuthenticationService } from '../../authentication.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ServerError } from '@models/server-error';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    RouterLink,
  ],
})
export class SignupComponent implements OnInit {
  @ViewChild('signupForm', { static: false }) signupForm!: NgForm;
  strongRegex = CustomValidators.PASSWORD_REGEXP;
  promptLabel = '';
  passwordValidClass = 'ng-invalid';
  formErrorMessages = {
    firstName: '',
    lastName: '',
    email: '',
  };
  passwordErrorMessage = '';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private translationService: TranslationService,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.promptLabel = this.translationService.get('validation', 'password');
  }

  async register() {
    this.formErrorMessages = {
      firstName: '',
      lastName: '',
      email: '',
    };
    this.passwordErrorMessage = '';

    if (this.signupForm.form.invalid) {
      this.formErrorMessages = this.translationService.validate(
        this.signupForm
      );
      this.passwordError(this.signupForm.controls['password'].value);
      return;
    }

    const user: AuthUser = this.signupForm.value;
    try {
      await this.authenticationService.register(user);

      this.messageService
        .translate('messages')
        .success('userSuccessfulyRegistered')
        .showMessage();
      this.router.navigate(['/prijava']);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  checkPasswordStrength(password: Password) {
    this.passwordValidClass = 'ng-invalid';

    if (password.value && this.strongRegex.test(password.value)) {
      this.passwordValidClass = 'ng-valid';
    }
  }

  private passwordError(password: string) {
    if (!this.strongRegex.test(password)) {
      this.passwordErrorMessage = this.translationService.get(
        'validation',
        'pattern',
        {
          field: this.translationService.get('properties', 'password'),
        }
      );
    }
  }
}
