import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ButtonModule } from 'primeng/button';

import { ServerError } from '@models/server-error';
import { AuthenticationService } from '@authentication/authentication.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { MessageService } from '@services/message.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'verification',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
  private storageService: StorageService;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService
  ) {
    this.storageService = new StorageService();
  }

  ngOnInit(): void {
    this.profile();
  }

  async profile() {
    try {
      const profile = await this.authenticationService.profile();

      if (profile.basicInfo.verified) {
        this.storageService.setValue(profile).toString().store('user');
        this.router.navigateByUrl('evidencije');
      }
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  async sendToVerify() {
    try {
      await this.authenticationService.sendToVerify();

      this.messageService
        .translate('messages')
        .success('resendVerificationMail')
        .showMessage();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }
}
