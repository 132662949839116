import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { ServerError } from '@models/server-error';
import { AuthenticationService } from '@authentication/authentication.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { MessageService } from '@services/message.service';
import { UserProfileService } from '@services/user-profile.service';

export const registrationGuard: CanActivateFn = async (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const userProfileService = inject(UserProfileService);
  const errorHandlerService = inject(ErrorHandlerService);
  const messageService = inject(MessageService);
  const router = inject(Router);

  const token = route.params['verifyToken'];

  try {
    const verify = await authenticationService.verify(token);

    userProfileService.setVerification(verify['verified']);
    messageService.translate('messages').success('userVerified').showMessage();
    router.navigateByUrl('/evidencije');
  } catch (error) {
    errorHandlerService.handleError(new ServerError(error));
  }

  return true;
};
