import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm, Validators, FormsModule } from '@angular/forms';

import { AddressSearchComponent } from 'src/app/shared/components/address-search/address-search.component';
import { CountrySelectComponent } from 'src/app/shared/components/country-select/country-select.component';
import { FormControlService } from 'src/app/shared/services/form-control.service';
import { NewTenant } from '../../tenants.interface';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ButtonModule } from 'primeng/button';
import { NgClass } from '@angular/common';
import { CountrySelectComponent as CountrySelectComponent_1 } from '../../../../../shared/components/country-select/country-select.component';
import { AddressSearchComponent as AddressSearchComponent_1 } from '../../../../../shared/components/address-search/address-search.component';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';

@Component({
    selector: 'tenants-form',
    templateUrl: './tenants-form.component.html',
    styleUrls: ['./tenants-form.component.scss'],
    standalone: true,
    imports: [
        SidebarModule,
        FormsModule,
        InputTextModule,
        AddressSearchComponent_1,
        CountrySelectComponent_1,
        NgClass,
        ButtonModule,
    ],
})
export class TenantsFormComponent implements OnInit {
  sidebarVisible = false;
  @ViewChild('tenantForm', { static: false }) tenantForm!: NgForm;
  @ViewChild(AddressSearchComponent) addressSearch!: AddressSearchComponent;
  @ViewChild(CountrySelectComponent) countrySelect!: CountrySelectComponent;
  @Output() submitted = new EventEmitter<NewTenant>();
  formErrorMessages = {
    tenantName: '',
    companyName: '',
    country: '',
    oib: '',
    vat: '',
    jbkjs: '',
  };

  constructor(
    private formControlService: FormControlService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.formControlService.change({
      address: undefined,
      country: undefined,
    });
  }

  onCreateTenant(): void {
    this.idTypeRequired();
    this.formErrorMessages = this.translationService.validate(this.tenantForm);

    if (this.tenantForm.form.valid) {
      let newTenant: NewTenant = this.tenantForm.value;
      
      this.addressSearch.dropdown.resetFilter();
      this.addressSearch.locations.next([]);
      this.tenantForm.reset();

      this.submitted.emit(newTenant);
    }
  }

  private idTypeRequired() {
    if (this.countrySelect.selectedCountry === 'Croatia') {
      const oib = this.tenantForm.form.get('oib');
      if (oib) {
        oib.addValidators([Validators.required]);
        oib.updateValueAndValidity();
      }

      const vat = this.tenantForm.form.get('vat');
      if (vat) {
        vat.removeValidators([Validators.required]);
        vat.updateValueAndValidity();
      }
    }

    if (
      this.countrySelect.selectedCountry === 'BH' ||
      this.countrySelect.selectedCountry === 'Serbia'
    ) {
      const vat = this.tenantForm.form.get('vat');
      if (vat) {
        vat.addValidators([Validators.required]);
        vat.updateValueAndValidity();
      }

      const oib = this.tenantForm.form.get('oib');
      if (oib) {
        oib.removeValidators([Validators.required]);
        oib.updateValueAndValidity();
      }
    }
  }
}
