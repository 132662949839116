<p-panel styleClass="cookie-consent-panel">
  <ng-template pTemplate="header">
    <img src="/assets/img/cookie.png" alt="Swing Consulting logo">
    <span>Pravila prikupljanja kolačića</span>
  </ng-template>
  <p>
    <strong>SWING Consulting eProtokol</strong> koristi 'kolačiće' za što
    trebamo Vašu privolu. Klikom na 'Prihvati sve kolačiće' pristajete na
    anonimno prikupljanje svih 'kolačića'. Ako želite uskratiti privolu
    na prikupljanje dodatnih kolačića to možete učiniti klikom na 'Upravljaj
    kolačićima'. Način definiranja politike kolačića u skladu je s
    <a href="#">Pravilima privatnosti</a>.
  </p>
  <div class="accept-cookies">
    <p-button label="Prihvati sve kolačiće" class="cookie-consent-action"
      styleClass="p-button-primary main" (onClick)="acceptCookies('all')">
    </p-button>
    <p-button label="Samo osnovni kolačići" class="cookie-consent-action"
      styleClass="p-button-primary main" (onClick)="acceptCookies('neccesary')">
    </p-button>
  </div>
  <p-button label="Upravljaj kolačićima" styleClass="p-button-secondary main"
    (onClick)="visible = true">
  </p-button>
</p-panel>

<p-dialog styleClass="cookie-consent-dialog" [(visible)]="visible" [modal]="true"
  [draggable]="false" [resizable]="false"
>
  <ng-template pTemplate="header">
    <h3>Postavke kolačića</h3>
  </ng-template>
  <p>
    Korištenje naše aplikacije može uzrokovati skladištenje ili dohvaćanje
    informacija o Vašem pregledniku, uglavnom u formi 'kolačića'. Ove
    informacije mogu biti o Vama, Vašim preferencijama ili Vašem uređaju i
    uglavnom se koriste kako bi aplikacija radila onako kako to očekujete.
    Informacije Vas većinom ne identificiraju direktno, ali Vam mogu pružiti
    osobnije web iskustvo. S obzirom da poštujemo Vaše pravo na privatnost,
    možete onemogućiti prikupljanje nekih vrsta 'kolačića'. Kliknite na naslov
    neke kategorije kako bi saznali više. Posebno napominjemo kako
    onemogućavanje nekih vrsta 'kolačića' može utjecati na Vaše iskustvo rada s
    aplikacijom i uslugama koje nudimo.
  </p>
  <div class="switch-cookies">
    <h6>Samo osnovni kolačići</h6>
    <p-inputSwitch class="cookie-consent-switch" styleClass="input-switch"
      [(ngModel)]="cookieConsent.required" [readonly]="true">
    </p-inputSwitch>
  </div>
  <div class="switch-cookies">
    <h6>Prijava greške</h6>
    <p-inputSwitch class="cookie-consent-switch" styleClass="input-switch"
      [(ngModel)]="cookieConsent.bugReport">
    </p-inputSwitch>
  </div>
  <div class="switch-cookies">
    <h6>Podaci o platformi</h6>
    <p-inputSwitch class="cookie-consent-switch" styleClass="input-switch"
      [(ngModel)]="cookieConsent.deviceInfo">
    </p-inputSwitch>
  </div>
  <div class="switch-cookies">
    <h6>Podaci o lokaciji</h6>
    <p-inputSwitch class="cookie-consent-switch" styleClass="input-switch"
      [(ngModel)]="cookieConsent.location">
    </p-inputSwitch>
  </div>
  <ng-template pTemplate="footer">
    <p-button label="Spremi promjene" styleClass="p-button-primary info"
      (onClick)="consent()"></p-button>
    <p-button label="Prihvati sve kolačiće" styleClass="p-button-secondary info"
      (onClick)="acceptCookies('all')">
    </p-button>
    <p-button label="Odustani" styleClass="p-button-primary p-button-text info"
      (onClick)="visible = false">
    </p-button>
  </ng-template>
</p-dialog>
