<div class="page-wrapper">
  <div class="sticky-header">
    <app-header></app-header>
  </div>
  <div class="content-wrapper">
    <breadcrumb></breadcrumb>
    <div class="content">
      <router-outlet></router-outlet>
      <profile-modal></profile-modal>
    </div>
    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
</div>
