<div class="container">
  <div class="row">
    <div class="col align-self-center">
      <div class="wrapper">
        <form #f="ngForm">
          <div class="form-logo">
            <img src="/assets/img/logo/logo-name-125.png"
              srcset="/assets/img/logo/logo-name-125.png 125w,
                assets/img/logo/logo-name-100.png 100w,
                assets/img/logo/logo-name-75.png 75w,
                assets/img/logo/logo-name-50.png 50w"
              sizes="(max-width: 430px) 50px, (max-width: 530px) 75px,
                (max-width: 768px) 100px, 125px"
              alt="Swing">
          </div>
          <ng-container *ngIf="!(emailSent | async); else notification">
            <div class="form-header">
              <h5>Promjena lozinke</h5>
              <p>Unesite e-mail adresu kojom ste se registrirali za korištenje
                aplikacije kako bi promjenili lozinku korisničkog računa</p>
            </div>
            <span class="p-float-label" style="margin-top: 2rem;">
              <input pInputText id="email"
                [(ngModel)]="user.email" name="email" #email="ngModel"
                [ngClass]="{'not-submitted': !submitted}" required email />
              <label for="email" [ngClass]="{'not-submitted': !submitted}">
                Email
              </label>
            </span>
            <small class="invalid-input" *ngIf="!email.valid && submitted">
              E-mail adresa nema ispravan format
            </small>
            <p-button label="Pošalji" styleClass="p-button-lg"
              (onClick)="recoverPassword()">
            </p-button>
          </ng-container>
          <ng-template #notification>
            <div class="form-header">
              <h5>Promjena lozinke</h5>
              <p>Poslana vam je obavijest na e-mail koji ste unijeli,
                a koja sadržava link za izmjenu lozinke.</p>
              <p>Molimo vas provjerite svoj inbox i
                slijedite upute iz e-maila.</p>
            </div>
          </ng-template>
        </form>
      </div>
    </div>
  </div>
</div>
