import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    standalone: true
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  redirectTimeout!: number;

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
    this.redirectTimeout = window.setTimeout(() => {
      this.router.navigate(['/']);
    }, 5000);
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.redirectTimeout);
  }
}
