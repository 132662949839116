import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';

import { ServerError } from '@models/server-error';
import { AuthenticationService } from '@authentication/authentication.service';
import { ErrorHandlerService } from '@services/error-handler.service';

class Password {
  constructor(public password = '', public token = '') {}
}

@Component({
  selector: 'new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    TranslateModule,
    ButtonModule,
    PasswordModule,
  ],
})
export class NewPasswordComponent implements OnInit {
  user = new Password();
  submitted = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.user.token =
      this.activatedRoute.snapshot.paramMap.get('resetToken') || '';
  }

  async resetPassword() {
    this.submitted = true;

    try {
      await this.authenticationService.resetPassword(
        this.user.password,
        this.user.token
      );

      this.submitted = false;
      this.router.navigate(['/prijava']);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  htmlElementClass(): string {
    if (!this.submitted) {
      return 'password not-submitted';
    } else {
      return 'password';
    }
  }
}
