import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { CookieConsentComponent } from '@components/cookie-consent/cookie-consent.component';
import { MessageComponent } from '@components/message/message.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  standalone: true,
  imports: [NgIf, RouterOutlet, CookieConsentComponent, MessageComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [CookieService],
})
export class AppComponent implements OnInit {
  showConsentForm = false;

  constructor(
    private cookieService: CookieService,
    private translate: TranslateService
  ) {
    this.addLanguagesForTranslation();
  }

  ngOnInit(): void {
    const exists = this.cookieService.check('consent');
    const value = this.cookieService.get('consent');

    if (!exists || !value) {
      this.showConsentForm = true;
    }
  }

  private addLanguagesForTranslation() {
    this.translate.addLangs(['bh, en, hr, sl, sr']);
    this.translate.setDefaultLang('hr');
    this.translate.use('hr');
  }

  acceptCookieConsent(accept: boolean) {
    this.showConsentForm = !accept;
  }
}
