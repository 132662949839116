import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';

import { Tenant } from '@tenants/tenants.interface';
import { User } from '@interfaces/user';
import { ProfileModalService } from '@services/profile-modal.service';
import { StorageService } from '@services/storage.service';
import { UserProfileService } from '@services/user-profile.service';

@Component({
  selector: 'profile-modal',
  standalone: true,
  imports: [NgIf, NgClass, AsyncPipe, RouterLink, ButtonModule, SidebarModule],
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit, OnDestroy {
  sidebar = false;
  image = new BehaviorSubject<string>('');
  name = new BehaviorSubject<string>('');
  user!: User;
  tenant!: Tenant;
  administration = false;
  imageSubscription!: Subscription;
  userSubscription!: Subscription;
  modalSubscription!: Subscription;
  timeout!: number;
  private storageService: StorageService;

  constructor(
    private router: Router,
    private profileModalService: ProfileModalService,
    private userProfileService: UserProfileService
  ) {
    this.storageService = new StorageService();
  }

  ngOnInit(): void {
    this.image.next(this.userProfileService.getImage());
    this.name.next(this.userProfileService.getName());
    this.user = this.storageService.get('user').toJSON().value;
    this.tenant = this.storageService
      .get('activeTenant')
      .decrypt()
      .toJSON().value;
    this.administration = this.tenant.administration;
    this.checkSubscriptionChanges();
  }

  private checkSubscriptionChanges() {
    this.imageSubscription = this.userProfileService.imgUrl.subscribe(
      (isChanged) => {
        if (isChanged) {
          this.image.next(this.userProfileService.getImage());
        }
      }
    );
    this.userSubscription = this.userProfileService.user.subscribe(
      (isChanged) => {
        if (isChanged) {
          this.name.next(this.userProfileService.getName());
        }
      }
    );
    this.modalSubscription = this.profileModalService.modal.subscribe(
      (show) => (this.sidebar = show)
    );
  }

  action(route: string) {
    this.timeout = window.setTimeout(() => {
      this.hide();
      this.router.navigateByUrl(route);
    }, 500);
  }

  hide() {
    this.profileModalService.modalSource.next(false);
  }

  ngOnDestroy(): void {
    if (this.imageSubscription) {
      this.imageSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    window.clearTimeout(this.timeout);
  }
}
