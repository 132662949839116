<p-menubar styleClass="menu-bar" [model]="items">
  <ng-template pTemplate="start">
    <a routerLink="/">
      <img class="logo" src="assets/img/logo/logo-white.png" alt="Swing logo" />
    </a>
  </ng-template>

  <ng-template pTemplate="end">
    <div class="submenu">
      <a
        class="link"
        pTooltip="{{ 'tooltip.tenants' | translate }}"
        tooltipPosition="bottom"
        routerLink="/evidencije"
      >
        <i class="pi pi-list icon"></i>
      </a>
      <a
        class="link"
        pTooltip="{{ 'tooltip.settings' | translate }}"
        tooltipPosition="bottom"
        routerLink="/postavke"
      >
        <i class="pi pi-cog icon"></i>
      </a>
      <p-divider layout="vertical" styleClass="divider"></p-divider>
      <a class="user" type="button" (click)="showModal()">
        <div class="d-flex">
          <img
            class="rounded-circle"
            [src]="image | async"
            height="48"
            alt=""
          />
          <div class="info">
            <span>{{ userName }}</span>
            <span>{{ tenant.title }}</span>
          </div>
        </div>
      </a>
    </div>
  </ng-template>
</p-menubar>
