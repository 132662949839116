import { Component, ViewChild } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';

import { CustomValidators } from '@helpers/custom-validators';
import { ServerError } from '@models/server-error';
import { AuthUser } from '@authentication/authentication.interface';
import { AuthenticationService } from '@authentication/authentication.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { StorageService } from '@services/storage.service';
import { TenantsService } from '@tenants/tenants.service';
import { TranslationService } from '@services/translation.service';
import { UserProfileService } from '@services/user-profile.service';

@Component({
  selector: 'login',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink,
    ButtonModule,
    InputTextModule,
    PasswordModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @ViewChild('loginForm', { static: false }) loginForm!: NgForm;
  strongRegex = CustomValidators.PASSWORD_REGEXP;
  formErrorMessages = {
    email: '',
    password: '',
  };
  passwordErrorMessage = '';
  private storageService: StorageService;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private tenantService: TenantsService,
    private translationService: TranslationService,
    private userProfileService: UserProfileService
  ) {
    this.storageService = new StorageService();
  }

  async login() {
    this.formErrorMessages = {
      email: '',
      password: '',
    };
    this.passwordErrorMessage = '';

    if (this.loginForm.form.invalid) {
      this.formErrorMessages = this.translationService.validate(this.loginForm);
      return;
    }

    const user: AuthUser = this.loginForm.value;
    if (this.loginForm.form.invalid) {
      return;
    }

    try {
      const authentication = await this.authenticationService.login(user);

      this.storageService
        .setValue(authentication.authToken)
        .toString()
        .encrypt()
        .store('authToken');
      this.storageService
        .setValue(authentication.user)
        .toString()
        .store('user');
      this.userProfileService.setImage(authentication.user.profilePhoto);

      if (!this.storageService.has('previousSession')) {
        this.router.navigate(['/evidencije']);
        return;
      }
      this.interruptedSession();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  private async interruptedSession() {
    if (!this.storageService.has('previousSession')) {
      return;
    }

    const previousSession = this.storageService
      .get('previousSession')
      .toJSON().value;
    const url = previousSession['route'];
    const id = previousSession['tenantId'];

    try {
      const tenant = await this.tenantService.selectTenant(id);

      this.storageService
        .setValue(tenant)
        .toString()
        .encrypt()
        .store('activeTenant');
      this.storageService
        .setValue(tenant.memberPreferences.dashboardLayout)
        .store('dashboardConfiguration');
      this.storageService.remove('previousSession');

      this.router.navigateByUrl(url);
    } catch (error) {
      this.storageService.remove('previousSession');
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }
}
