import { BehaviorSubject } from 'rxjs';
import { Component } from '@angular/core';

import { AuthenticationService } from '../../authentication.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ServerError } from '@models/server-error';

class Password {
  constructor(public email = '') {}
}

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    InputTextModule,
    NgClass,
    ButtonModule,
    AsyncPipe,
  ],
})
export class ForgotPasswordComponent {
  user = new Password();
  submitted = false;
  emailSent = new BehaviorSubject<boolean>(false);

  constructor(
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  async recoverPassword() {
    this.submitted = true;

    try {
      await this.authenticationService.recoverPassword(this.user.email);

      this.submitted = false;
      this.emailSent.next(true);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }
}
