import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileModalService {
  modal!: Observable<boolean>;
  modalSource!: BehaviorSubject<boolean>;

  constructor() {
    this.modalSource = new BehaviorSubject<boolean>(false);
    this.modal = this.modalSource.asObservable();
  }

  showModal() {
    this.modalSource.next(true);
  }
}
