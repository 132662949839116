import { Component, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { IsActiveMatchOptions, RouterLink } from '@angular/router';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';
import { DividerModule } from 'primeng/divider';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';

import { ActiveTenant } from '@tenants/tenants.interface';
import { ProfileModalService } from '@services/profile-modal.service';
import { StorageService } from '@services/storage.service';
import { TranslationService } from '@services/translation.service';
import { UserProfileService } from '@services/user-profile.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    AsyncPipe,
    RouterLink,
    TranslateModule,
    DividerModule,
    MenubarModule,
    TooltipModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  items: MenuItem[] = [];
  userName = '';
  image = new BehaviorSubject<string>('');
  tenant!: ActiveTenant;
  queryParams = { page: '1', rows: '10' };
  activeOptions: IsActiveMatchOptions = {
    matrixParams: 'ignored',
    queryParams: 'ignored',
    paths: 'exact',
    fragment: 'ignored',
  };
  subscriptions: Subscription[] = [];
  private storageService: StorageService;

  constructor(
    private profileModalService: ProfileModalService,
    private translationService: TranslationService,
    private userProfileService: UserProfileService
  ) {
    this.storageService = new StorageService();
  }

  ngOnInit(): void {
    this.userName = this.userProfileService.getName();
    this.activeTenant();
    this.userAvatar();
    this.headerItems();

    const subscription = this.userProfileService.imgUrl.subscribe((isChanged) =>
      isChanged ? this.userAvatar() : undefined
    );
    this.subscriptions.push(subscription);
  }

  showModal() {
    this.profileModalService.showModal();
  }

  private activeTenant() {
    this.tenant = this.storageService
      .get('activeTenant')
      .decrypt()
      .toJSON().value['tenantData'];
  }

  private userAvatar() {
    this.image.next(this.userProfileService.getImage());
  }

  private headerItems() {
    const subscription = forkJoin([
      this.translationService.getStream('labels.incomingDocuments'),
      this.translationService.getStream('labels.outgoingDocuments'),
      this.translationService.getStream('labels.partners'),
    ]).subscribe(
      ([incomingDocumentsLabel, outgoingDocumentsLabel, partnersLabel]: [
        string,
        string,
        string
      ]) => {
        this.items = [
          {
            label: incomingDocumentsLabel,
            routerLink: 'ulazni-dokumenti',
            queryParams: { page: '1', rows: '10' },
            styleClass: 'module',
            routerLinkActiveOptions: this.activeOptions,
          },
          {
            label: outgoingDocumentsLabel,
            routerLink: 'izlazni-dokumenti',
            queryParams: { page: '1', rows: '10' },
            styleClass: 'module',
            routerLinkActiveOptions: this.activeOptions,
          },
          {
            label: partnersLabel,
            routerLink: '/partneri',
            styleClass: 'module',
            routerLinkActiveOptions: this.activeOptions,
          },
        ];
      }
    );
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    if (!this.subscriptions) {
      return;
    }

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
