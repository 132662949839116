<div class="container">
  <div class="row">
    <div class="col">
      <div class="wrapper">
        <form #signupForm="ngForm">
          <div class="form-logo">
            <img
              src="/assets/img/logo/logo-name-125.png"
              srcset="
                /assets/img/logo/logo-name-125.png 125w,
                assets/img/logo/logo-name-100.png  100w,
                assets/img/logo/logo-name-75.png    75w,
                assets/img/logo/logo-name-50.png    50w
              "
              sizes="(max-width: 430px) 50px, (max-width: 530px) 75px,
                (max-width: 768px) 100px, 125px"
              alt="Swing"
            />
          </div>
          <div class="form-header">
            <h5>Registrirajte se</h5>
            <p>za pristup eProtokolu</p>
          </div>
          <span class="p-float-label margin-top-2">
            <input
              pInputText
              class="width-100"
              type="text"
              id="firstName"
              ngModel
              name="firstName"
              #firstName="ngModel"
              required
            />
            <label for="firstName">Ime</label>
          </span>
          <span class="validation-error">
            {{ formErrorMessages.firstName }}
          </span>
          <span class="p-float-label margin-top-1">
            <input
              pInputText
              class="width-100"
              type="text"
              id="lastName"
              ngModel
              name="lastName"
              #lastName="ngModel"
              required
            />
            <label for="lastName">Prezime</label>
          </span>
          <span class="validation-error">
            {{ formErrorMessages.lastName }}
          </span>
          <span class="p-float-label margin-top-1">
            <input
              pInputText
              class="width-100"
              type="text"
              id="email"
              ngModel
              name="email"
              #email="ngModel"
              required
              email
            />
            <label for="email">Email</label>
          </span>
          <span class="validation-error">
            {{ formErrorMessages.email }}
          </span>
          <span class="p-float-label margin-top-1">
            <p-password
              #signupPassword
              inputId="password"
              ngModel
              name="password"
              #password="ngModel"
              [toggleMask]="true"
              class="{{ passwordValidClass }}"
              styleClass="width-100"
              inputStyleClass="width-100"
              weakLabel="Slabo"
              mediumLabel="Srednje"
              strongLabel="Snažno"
              strongRegex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9_])(?=.{8,})"
              [promptLabel]="promptLabel"
              (ngModelChange)="checkPasswordStrength(signupPassword)"
            >
            </p-password>
            <label for="password">Lozinka</label>
          </span>
          <span class="validation-error">
            {{ passwordErrorMessage }}
          </span>
          <button
            pButton
            class="signup-button"
            label="Registracija"
            (click)="register()">
          </button>
        </form>
      </div>
      <div class="login-link">
        <a routerLink="/prijava">Već imate račun? Prijavite se</a>
      </div>
    </div>
  </div>
</div>
