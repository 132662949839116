import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Column } from 'src/app/shared/interfaces/column';
import { Invite } from '@app/shared/interfaces/invite';
import { TenantsService } from '../../tenants.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import {
  NgFor,
  NgClass,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgIf,
} from '@angular/common';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { ServerError } from '@models/server-error';

@Component({
  selector: 'tenants-invites',
  templateUrl: './tenants-invites.component.html',
  styleUrls: ['./tenants-invites.component.scss'],
  standalone: true,
  imports: [
    SidebarModule,
    TableModule,
    NgFor,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgIf,
    ButtonModule,
    TooltipModule,
    TranslateModule,
  ],
})
export class TenantsInvitesComponent implements OnInit, OnDestroy {
  @Input() invites!: Invite[];
  @Output() inviteAction = new EventEmitter<boolean>();
  cols!: Column[];
  visibility = false;
  timeout!: number;

  constructor(
    private tenantsService: TenantsService,
    private translationService: TranslationService,
    private errorHandlerService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.cols = [
      {
        field: 'tenant',
        header: this.translationService.get('labels', 'tenant'),
      },
      { field: 'from', header: this.translationService.get('labels', 'from') },
      {
        field: 'status',
        header: this.translationService.get('labels', 'status'),
      },
    ];
  }

  async onAction(action: string, invite: Invite) {
    try {
      await this.tenantsService.invitationAction(action, invite.token);

      this.removeInvite(invite);
      this.timeout = window.setTimeout(() => {
        this.visibility = false;
        this.inviteAction.emit(true);
      }, 500);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  removeInvite(invite: Invite) {
    let index = this.invites.findIndex((item) => item === invite);
    this.invites.splice(index, 1);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }
}
