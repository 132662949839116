<ng-container *ngIf="(loaded | async); else loader">
  <div class="container">
    <div class="row">
      <div class="col-xxl-7 col-xl-8 col-lg-9 col-md-10 col-sm-12">
        <div class="tenant-header">
          <p-button
            styleClass="p-button-outlined p-button-secondary action-btn"
            badgeClass="p-badge-success"
            label="Pozivnice"
            icon="pi pi-envelope"
            [badge]="invitesNumber()"
            (onClick)="toogleInvitesSidebar()"
          >
          </p-button>
          <div class="list-header">
            <span class="user-name">{{ name }}</span>
            <p-button
              styleClass="p-button-outlined p-button-secondary action-btn"
              label="Odjava"
              (onClick)="logout()"
            >
            </p-button>
          </div>
        </div>
        <tenants-list
          [tenants]="tenants"
          (selectedTenant)="selectTenant($event)"
          (toogleSidebarEmmiter)="toogleTenantFormSidebar()"
        >
        </tenants-list>
        <div class="validation-error">
          {{ tenantsAllowedError }}
        </div>
        <tenants-form (submitted)="store($event)"></tenants-form>
        <tenants-invites
          [invites]="(invites | async) || []"
          (inviteAction)="tenant()"
        >
        </tenants-invites>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <loader></loader>
</ng-template>
