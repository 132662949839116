import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';

import { Empty } from 'src/app/shared/helpers/array';
import { Column } from 'src/app/shared/interfaces/column';
import { TenantDetails } from '../../tenants.interface';
import { TenantsService } from '../../tenants.service';
import { ErrorHandlerService } from 'src/app/shared/services/error-handler.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
  NgFor,
  NgSwitch,
  NgSwitchCase,
  NgIf,
  NgSwitchDefault,
} from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ServerError } from '@models/server-error';

@Component({
  selector: 'tenants-list',
  templateUrl: './tenants-list.component.html',
  styleUrls: ['./tenants-list.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0, height: 0 })),
      state('*', style({ opacity: 1, height: '*' })),
      transition(
        ':enter, :leave',
        [animate('{{ animationDuration }} ease-in-out')],
        { params: { animationDuration: '0.3s' } }
      ),
    ]),
  ],
  standalone: true,
  imports: [
    TableModule,
    InputTextModule,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgIf,
    NgSwitchDefault,
    FormsModule,
    TranslateModule,
  ],
})
export class TenantsListComponent implements AfterViewInit {
  @Input() tenants: Array<TenantDetails> = [];
  @Output() toogleSidebarEmmiter = new EventEmitter();
  @Output() selectedTenant = new EventEmitter<number>();
  columns: Array<Column> = [
    { field: 'id' },
    { field: 'name' },
    { field: 'active' },
  ];
  emails: string = '';
  selectedTenants: Array<TenantDetails> = [];
  invalidEmails: Array<string> = [];
  animationDuration = '0s';

  constructor(
    private tenantsService: TenantsService,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService
  ) {}

  ngAfterViewInit() {
    this.animationDuration = '0.3s';
  }

  private validateEmail(): Array<string> {
    this.invalidEmails = [];
    const EMAIL_REGEXP =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const emailArray = this.emails.split(',').map((word) => {
      const email = word.trim();
      if (!EMAIL_REGEXP.test(email)) {
        this.invalidEmails.push(email);
      }
      return email;
    });
    return emailArray;
  }

  public index(tenant: TenantDetails): number {
    return this.tenants.indexOf(tenant) + 1;
  }

  public selectTenant(tenant: TenantDetails) {
    if (tenant.active) {
      this.selectedTenant.emit(tenant.id);
    }
  }

  public toogleTenantFormSidebar() {
    this.toogleSidebarEmmiter.emit();
  }

  public async sendInvitation() {
    const validatedEmails = this.validateEmail();

    if (!Empty(this.invalidEmails)) {
      const email = this.invalidEmails.join(', ');

      this.messageService
        .translate('validation', '', { email })
        .error('email')
        .showMessage();

      return;
    }

    try {
      await this.tenantsService.sendInvitation(
        this.selectedTenants.map((tenant) => tenant.id),
        validatedEmails
      );

      this.selectedTenants = [];
      this.emails = '';

      this.messageService
        .translate('messages.administration')
        .success('invitationsSuccessfullySent')
        .showMessage();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }
}
