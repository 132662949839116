<p-sidebar
  styleClass="invites-sidebar"
  position="left"
  [(visible)]="visibility"
>
  <h3>{{ "labels.tenantInvitesReceivedList" | translate }}</h3>
  <p-table
    [columns]="cols"
    [value]="invites || []"
    styleClass="invites-table"
    responsiveLayout="stack"
    breakpoint="595px"
    [scrollable]="true"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [ngClass]="rowData.status">
        <td *ngFor="let col of columns" [ngSwitch]="col.field">
          <span class="p-column-title stack-layout">{{ col.header }}</span>
          <span *ngSwitchCase="'status'">
            {{
              rowData[col.field] === "expired"
                ? ("labels.tenantInvitesStatusExpired" | translate)
                : ("labels.tenantInvitesStatusValid" | translate)
            }}
          </span>
          <span *ngSwitchDefault>{{ rowData[col.field] }}</span>
        </td>
        <td>
          <div *ngIf="rowData.status === 'valid'" class="button-container">
            <button
              pButton
              class="p-button-success action-button"
              icon="pi pi-check"
              pTooltip="{{ 'tooltip.accept' | translate }}"
              tooltipPosition="top"
              [showDelay]="500"
              (click)="onAction('accept', rowData)"
            ></button>
            <button
              pButton
              class="p-button-danger action-button"
              icon="pi pi-times"
              pTooltip="{{ 'tooltip.reject' | translate }}"
              tooltipPosition="top"
              [showDelay]="500"
              (click)="onAction('reject', rowData)"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-sidebar>
