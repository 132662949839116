<p-table
  #dt
  styleClass="p-datatable-striped"
  [columns]="columns"
  [value]="tenants"
  [rowHover]="true"
  [globalFilterFields]="['name']"
  [(selection)]="selectedTenants"
  selectionMode="multiple"
  responsiveLayout="scroll"
  [scrollable]="true"
>
  <ng-template pTemplate="caption">
    <div class="title">
      <h4>{{ "labels.tenantList" | translate }}</h4>
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pInputText
          #filterName
          id="filter-input"
          type="text"
          placeholder="{{ 'placeholder.searchByName' | translate }}"
          (input)="dt.filterGlobal(filterName.value, 'contains')"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let column of columns" class="{{ column.field }}">
        {{ "table.header.tenant" + column.field | translate }}
      </th>
      <th style="width: 10%">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr class="tenant">
      <td
        *ngFor="let column of columns"
        class="{{ column.field }}"
        [ngSwitch]="column.field"
        (click)="selectTenant(rowData)"
      >
        <span *ngSwitchCase="'id'">{{ index(rowData) }}</span>
        <span *ngSwitchCase="'active'" style="color: #ff0000">
          <span *ngIf="!rowData[column.field]">
            {{ "labels.disabled" | translate }}
          </span>
        </span>
        <span *ngSwitchDefault>{{ rowData[column.field] }}</span>
      </td>
      <td>
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary">
    <div
      *ngIf="selectedTenants.length"
      class="fade-button"
      [@fade]="{ value: '', params: { animationDuration: animationDuration } }"
    >
      <input
        style="width: 99%"
        type="text"
        pInputText
        placeholder="{{ 'placeholder.separateMailsByComma' | translate }}"
        [(ngModel)]="emails"
      />
      <button
        style="margin-top: 0.5rem"
        class="btn btn-lg btn-light"
        type="button"
        [disabled]="!emails"
        (click)="sendInvitation()"
      >
        <i class="pi pi-envelope"></i>
        {{ "labels.sendInvite" | translate }}
      </button>
    </div>
    <div
      *ngIf="!selectedTenants.length"
      class="fade-button"
      [@fade]="{ value: '', params: { animationDuration: animationDuration } }"
    >
      <button
        type="button"
        class="btn btn-lg btn-light"
        (click)="toogleTenantFormSidebar()"
      >
        <i class="pi pi-plus"></i>
        {{ "labels.newTenant" | translate }}
      </button>
    </div>
  </ng-template>
</p-table>
