import { Injectable } from '@angular/core';

import { AuthUser, Authentication } from './authentication.interface';
import { CookieConsent } from 'src/app/shared/interfaces/cookie-consent';
import { Message } from 'src/app/shared/interfaces/alert';
import { User } from 'src/app/shared/interfaces/user';
import { HttpClientService } from 'src/app/shared/services/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  httpClientService: HttpClientService;

  constructor() {
    this.httpClientService = new HttpClientService();
  }

  /**
   * Logs in a user with the provided AuthUser credentials.
   *
   * @param user - The AuthUser object containing the user's login credentials.
   * @returns A Promise that resolves to an Authentication object containing the user's authentication details.
   */
  login(user: AuthUser): Promise<Authentication> {
    return this.httpClientService
      .method('POST')
      .route('/login')
      .body(user)
      .onError('noNavigation')
      .response<Authentication>();
  }

  /**
   * Registers a new user with the provided AuthUser credentials.
   *
   * @param user - The AuthUser object containing the user's registration credentials.
   * @returns A Promise that resolves to an Authentication object containing the user's authentication details.
   */
  register(user: AuthUser): Promise<Authentication> {
    return this.httpClientService
      .method('POST')
      .route('/register')
      .body(user)
      .onError('noNavigation')
      .response<Authentication>();
  }

  /**
   * Logs out the current user.
   *
   * @returns A Promise that resolves to a string indicating the logout status.
   */
  logout(): Promise<string> {
    return this.httpClientService
      .method('POST')
      .route('/logout')
      .withAuthToken()
      .response<string>();
  }

  /**
   * Verifies a user's email address using the provided token.
   *
   * @param token - The token used to verify the user's email address.
   * @returns A Promise that resolves to an object indicating whether the verification was successful.
   */
  verify(token: string): Promise<Record<string, boolean>> {
    return this.httpClientService
      .method('GET')
      .route(`/verify/${token}`)
      .withAuthToken()
      .response<Record<string, boolean>>();
  }

  /**
   * Sends a request to verify the user's email address.
   *
   * @returns A Promise that resolves to an object indicating whether the email verification request was successful.
   */
  sendToVerify(): Promise<Record<string, boolean>> {
    return this.httpClientService
      .method('GET')
      .route('/verify/send')
      .withAuthToken()
      .response<Record<string, boolean>>();
  }

  /**
   * Retrieves the current user's profile information.
   *
   * @returns A Promise that resolves to the current user's profile data.
   */
  profile(): Promise<User> {
    return this.httpClientService
      .method('GET')
      .route('/profile')
      .withAuthToken()
      .response<User>();
  }

  /**
   * Resets the user's password using the provided token.
   *
   * @param password - The new password to set.
   * @param token - The token used to reset the password.
   * @returns A Promise that resolves to an object containing authentication information.
   */
  resetPassword(password: string, token: string): Promise<Authentication> {
    return this.httpClientService
      .method('POST')
      .route('/password/reset')
      .body({ token, password })
      .response<Authentication>();
  }

  /**
   * Sends a request to recover the user's password using the provided email address.
   *
   * @param email - The email address associated with the user's account.
   * @returns A Promise that resolves to an object containing authentication information.
   */
  recoverPassword(email: string): Promise<Authentication> {
    return this.httpClientService
      .method('POST')
      .route('/password/recover')
      .body({ email })
      .response<Authentication>();
  }

  /**
   * Sends a request to the server to record the user's consent for a cookie policy.
   *
   * @param consent - The consent object containing the user's cookie preferences.
   * @returns A Promise that resolves to a message indicating the success or failure of the consent request.
   */
  consent(consent: CookieConsent): Promise<Message> {
    return this.httpClientService
      .method('POST')
      .route('/api/consent')
      .body({ consent })
      .response<Message>();
  }
}
