import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { authenticationGuard } from '@guards/authentication.guard';
import { invitationGuard } from '@guards/invitation.guard';
import { registrationGuard } from '@guards/registration.guard';
import { verificationGuard } from '@guards/verification.guard';
import { ForgotPasswordComponent } from '@authentication/components/forgot-password/forgot-password.component';
import { LoginComponent } from '@authentication/components/login/login.component';
import { LogoutComponent } from '@authentication/components/logout/logout.component';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { NewPasswordComponent } from '@authentication/components/new-password/new-password.component';
import { PageNotFoundComponent } from '@components/page-not-found/page-not-found.component';
import { SignupComponent } from '@authentication/components/signup/signup.component';
import { TenantsComponent } from '@tenants/pages/tenants/tenants.page';
import { VerificationComponent } from '@authentication/components/verification/verification.component';

const routes: Routes = [
  {
    path: 'lozinka',
    title: 'Nova lozinka',
    component: ForgotPasswordComponent,
  },
  {
    path: 'prijava',
    title: 'Prijava',
    component: LoginComponent,
  },
  {
    path: 'odjava',
    title: 'Odjava',
    component: LogoutComponent,
  },
  {
    path: 'lozinka/nova/:resetToken',
    title: 'Nova lozinka',
    component: NewPasswordComponent,
  },
  {
    path: 'registracija',
    title: 'Registracija',
    component: SignupComponent,
  },
  {
    canActivate: [authenticationGuard, verificationGuard],
    path: 'evidencije',
    title: 'Evidencije',
    component: TenantsComponent,
  },
  {
    path: 'potvrda',
    title: 'Potvrda',
    component: VerificationComponent,
  },
  {
    canActivate: [registrationGuard],
    children: [],
    path: 'registracija/potvrda/:verifyToken',
    title: 'Registracija',
  },
  {
    canActivate: [invitationGuard],
    children: [],
    path: 'evidencije/pozivnica/prihvati/:acceptToken',
    title: 'Evidencije',
  },
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@dashboard/dashboard-routing.module').then(
            (m) => m.DashboardRoutingModule
          ),
        canActivate: [authenticationGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('@records/records-routing.module').then(
            (m) => m.RecordsRoutingModule
          ),
        canActivate: [authenticationGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'partneri',
        loadChildren: () =>
          import('@partners/partners-routing.module').then((m) => m.PartnersRoutingModule),
        canActivate: [authenticationGuard],
      },
      {
        path: 'postavke',
        loadChildren: () =>
          import('@setup/setup-routing.module').then(
            (m) => m.SetupRoutingModule
          ),
        canActivate: [authenticationGuard],
      },
      {
        path: 'administracija',
        loadChildren: () =>
          import('@administration/administration-routing.module').then(
            (m) => m.AdministrationRoutingModule
          ),
        canActivate: [authenticationGuard],
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
