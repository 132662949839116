import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgIf, AsyncPipe } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { ButtonModule } from 'primeng/button';

import { LoaderComponent } from '@components/loaders/loader/loader.component';
import { TenantsFormComponent } from '@tenants/components/tenants-form/tenants-form.component';
import { TenantsInvitesComponent } from '@tenants/components/tenants-invites/tenants-invites.component';
import { TenantsListComponent } from '@tenants/components/tenants-list/tenants-list.component';
import { ServerError } from '@models/server-error';
import { Invite } from '@app/shared/interfaces/invite';
import { License, NewTenant, TenantDetails } from '@tenants/tenants.interface';
import { ErrorHandlerService } from '@services/error-handler.service';
import { MessageService } from '@services/message.service';
import { StorageService } from '@services/storage.service';
import { TenantsService } from '@tenants/tenants.service';
import { TranslationService } from '@services/translation.service';
import { UserProfileService } from '@services/user-profile.service';

@Component({
  selector: 'tenants',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    ButtonModule,
    LoaderComponent,
    TenantsFormComponent,
    TenantsInvitesComponent,
    TenantsListComponent,
  ],
  templateUrl: './tenants.page.html',
  styleUrls: ['./tenants.page.scss'],
})
export class TenantsComponent implements OnInit, OnDestroy {
  @ViewChild(TenantsInvitesComponent, { static: false })
  tenantsInvitesComponent!: TenantsInvitesComponent;
  @ViewChild(TenantsFormComponent, { static: false })
  tenantsFormComponent!: TenantsFormComponent;
  loaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tenants: Array<TenantDetails> = [];
  invites = new BehaviorSubject<Invite[]>([]);
  name: string = '';
  redirectTimeout: number = 0;
  isNewTenantAllowed: boolean = false;
  tenantsAllowedError: string = '';
  private storageService: StorageService;

  constructor(
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private messageService: MessageService,
    private tenantsService: TenantsService,
    private translationService: TranslationService,
    private userProfileService: UserProfileService
  ) {
    this.storageService = new StorageService();
  }

  ngOnInit(): void {
    this.name = this.userProfileService.getName();
    this.tenant();
  }

  logout(): void {
    this.redirectTimeout = window.setTimeout(() => {
      this.router.navigate(['../odjava']);
    }, 500);
  }

  async tenant() {
    try {
      const tenant = await this.tenantsService.tenant();

      this.isNewTenantAllowed = this.allowNewTenant(tenant.license);
      this.invites.next(tenant.invites);
      this.tenants = [...tenant.tenants];
      this.loaded.next(true);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  async selectTenant(id: number) {
    this.loaded.next(false);

    try {
      const tenant = await this.tenantsService.selectTenant(id);

      this.storageService
        .setValue(tenant)
        .toString()
        .encrypt()
        .store('activeTenant');

      this.storageService
        .setValue(tenant.memberPreferences.dashboardLayout)
        .store('dashboardConfiguration');

      this.router.navigate(['/']);
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  async store(tenant: NewTenant) {
    this.toogleTenantFormSidebar();

    try {
      const newTenant = await this.tenantsService.store(tenant);

      this.messageService
        .translate('messages.administration')
        .success('tenantSuccessfullyCreated')
        .showMessage();

      this.tenant();
    } catch (error) {
      this.errorHandlerService.handleError(new ServerError(error));
    }
  }

  toogleInvitesSidebar() {
    this.tenantsInvitesComponent.visibility = true;
  }

  toogleTenantFormSidebar() {
    if (this.isNewTenantAllowed) {
      this.tenantsFormComponent.sidebarVisible =
        !this.tenantsFormComponent.sidebarVisible;
      return;
    }
    this.tenantsAllowedError = this.translationService.get(
      'validation',
      'maxTenantsAllowed'
    );
  }

  invitesNumber(): string {
    let badgeNumber = 0;
    this.invites.getValue().forEach((invite) => {
      if (invite.status === 'valid') {
        badgeNumber++;
      }
    });
    return badgeNumber === 0 ? '' : badgeNumber.toString();
  }

  private allowNewTenant(license: License): boolean {
    let isNewTenantAllowed = false;

    if (license.tenantsCreated < license.tenantsAllowed) {
      isNewTenantAllowed = true;
    }

    return isNewTenantAllowed;
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.redirectTimeout);
  }
}
